import React, { useState, useEffect } from 'react';

function EntryPage({ onStart }) {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        // Fade in the entry page when it's loaded
        setOpacity(1);
    }, []);

    const handleStart = () => {
        // Fade out the entry page
        setOpacity(0);
        // After the fade-out transition is complete, call the onStart callback
        setTimeout(onStart, 1000);  // 500ms matches the transition duration
    };

    return (
        <div className="entry-page" style={{ opacity: opacity }}>
            <h1>Welcome to Calmood</h1>
            <p>Your journey to serenity begins now.</p>
            <button onClick={handleStart}>Start</button>
        </div>
    );
}

export default EntryPage;
