const quotes = [
    {
      text: 'Peace comes from within. Do not seek it without.',
      author: 'Buddha',
    },
    {
      text: 'Everything you\'ve ever wanted is on the other side of fear.',
      author: 'George Addair',
    },
    {
      text: 'The mind is everything. What you think you become.',
      author: 'Buddha',
    },
    {
      text: 'To the mind that is still, the whole universe surrenders.',
      author: 'Lao Tzu',
    },
    {
      text: 'Keep your face always toward the sunshine—and shadows will fall behind you.',
      author: 'Walt Whitman',
    },
    {
      text: 'The only way to do great work is to love what you do.',
      author: 'Steve Jobs',
    },
    {
      text: 'Calmness is the cradle of power.',
      author: 'Josiah Gilbert Holland',
    },
    {
      text: 'The best way to predict the future is to create it.',
      author: 'Abraham Lincoln',
    },
    {
      text: 'In the middle of every difficulty lies opportunity.',
      author: 'Albert Einstein',
    },
    {
      text: 'Don\'t let yesterday take up too much of today.',
      author: 'Will Rogers',
    },
    {
      text: 'The only limit to our realization of tomorrow is our doubts of today.',
      author: 'Franklin D. Roosevelt',
    },
    {
      text: 'Life is 10% what happens to us and 90% how we react to it.',
      author: 'Charles R. Swindoll',
    },
    {
      text: 'It does not matter how slowly you go as long as you do not stop.',
      author: 'Confucius',
    },
    {
      text: 'Your time is limited, don\'t waste it living someone else\'s life.',
      author: 'Steve Jobs',
    },
    {
      text: 'The only way to achieve the impossible is to believe it is possible.',
      author: 'Charles Kingsleigh',
    },
    {
      text: 'To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.',
      author: 'Ralph Waldo Emerson',
    },
    {
      text: 'The harder you work for something, the greater you’ll feel when you achieve it.',
      author: 'Unknown',
    },
    {
      text: 'Success is not final, failure is not fatal: It is the courage to continue that counts.',
      author: 'Winston Churchill',
    },
    {
      text: 'Believe you can and you\'re halfway there.',
      author: 'Theodore Roosevelt',
    },
    {
      text: 'When you have a dream, you\'ve got to grab it and never let go.',
      author: 'Carol Burnett',
    }
  ];
  
  export default quotes;
  