import React, { useEffect } from "react";
import ambients from "../../data/ambientData";

function AmbientControl({
  onAmbientChange,
  ambientVolume,
  onAmbientVolumeChange,
  currentAmbient,
}) {
  useEffect(() => {
    const audioElement = document.getElementById("ambient-audio");
    if (audioElement) {
      console.log("AmbientControl setting volume to:", ambientVolume);
      audioElement.volume = ambientVolume;
    }
  }, [ambientVolume]);

  const handleAmbientClick = (ambient) => {
    onAmbientChange(ambient);

    // Explicitly set the volume after changing the ambient sound
    const audioElement = document.getElementById("ambient-audio");
    if (audioElement) {
      console.log("Setting volume after ambient change:", ambientVolume);
      audioElement.volume = ambientVolume;
    }
  };

  const handleVolumeChange = (event) => {
    const volume = parseFloat(event.target.value);
    onAmbientVolumeChange(volume);

    const audioElement = document.getElementById("ambient-audio");
    if (audioElement) {
      audioElement.volume = volume;
    }
  };

  return (
    <div className="controls">
      <div className="controls-header">
        <h4>Ambients</h4>
        <div className="volume-controls">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 0 24 24"
            width="20"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"
              fill="currentColor"
            />
          </svg>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={ambientVolume}
            onChange={handleVolumeChange}
          />
        </div>
      </div>
      <div className="ambient-thumbnails">
        {ambients.map((ambient, index) => (
          <div
            key={index}
            onClick={() => handleAmbientClick(ambient)}
            className={`ambient-thumbnail ${
              currentAmbient && ambient.name === currentAmbient.name
                ? "active"
                : ""
            }`}
          >
            <img
              src={ambient.thumbnail}
              alt={ambient.name}
              title={ambient.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AmbientControl;
