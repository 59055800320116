import React, { useState, useEffect } from 'react';

function TimeDisplay() {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000); // Update every minute

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = `${String(currentTime.getHours()).padStart(2, '0')}:${String(currentTime.getMinutes()).padStart(2, '0')}`;
    const formattedDate = currentTime.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

    return (
        <div className="time-display">
            
            <div className="clock">{formattedTime}</div>
            <div className='date'>{formattedDate}</div>
        </div>
    );
}

export default TimeDisplay;
