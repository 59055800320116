import React, { useState } from 'react';
import EntryPage from './components/EntryPage';
import MainApp from './components/MainApp';
import './styles/main.sass';
import ReactGA from 'react-ga';




ReactGA.initialize("G-Q2F2N6RR8E");

ReactGA.pageview(window.location.pathname + window.location.search);



function App() {
    const [started, setStarted] = useState(false);

    if (!started) {
        return <EntryPage onStart={() => setStarted(true)} />;
    }

    return <MainApp />;
}

export default App;
