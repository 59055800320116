//ControlContainer.js

import React, { useState, useEffect, useRef } from 'react';
import AmbientControl from './Nav/AmbientControl';
import MusicControls from './Nav/MusicControls';
import MoreControls from './Nav/MoreControls';

function ControlContainer({
  activeControl,
  onAmbientChange,
  playlists,
  currentPlaylist,
  currentSongIndex,
  isPlaying,
  onNextSong, // Pass the prop for handling the next song
  onPrevSong,
  ambientVolume,
  onAmbientVolumeChange,
  musicVolume,
  onMusicVolumeChange,
  setIsPlaying,
  onPlaylistChange,
  showQuotes,
  setShowQuotes,
  showTime,
  setShowTime,
  currentAmbient,
  setSelectedOption,
  selectedOption
}) {
  const [fadeIn, setFadeIn] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (activeControl) {
      setFadeIn(true);
    } else {
      const containerElement = containerRef.current;
      const handleTransitionEnd = () => {
        setFadeIn(false);
      };

      containerElement.addEventListener('transitionend', handleTransitionEnd);
      return () => {
        containerElement.removeEventListener('transitionend', handleTransitionEnd);
      };
    }
  }, [activeControl]);

  const containerClass = fadeIn ? 'control-container fade-in' : 'control-container initiating-fade-out';

  return (
    <div className={containerClass} ref={containerRef}>
      <div className="wrapper">
        {activeControl === 'Ambient' && (
          <AmbientControl
            onAmbientChange={onAmbientChange}
            ambientVolume={ambientVolume}
            onAmbientVolumeChange={onAmbientVolumeChange}
            currentAmbient={currentAmbient}
          />
        )}
        {activeControl === 'Music' && (
          <MusicControls
            playlists={playlists}
            currentPlaylist={currentPlaylist}
            currentSongIndex={currentSongIndex}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            onPrevSong={onPrevSong}
            onNextSong={onNextSong}
            ambientVolume={ambientVolume}
            onAmbientVolumeChange={onAmbientVolumeChange}
            musicVolume={musicVolume}
            onMusicVolumeChange={onMusicVolumeChange}
            onPlaylistChange={onPlaylistChange}
          />
        )}
        {activeControl === 'More' && (
          <MoreControls
            showQuotes={showQuotes}
            setShowQuotes={setShowQuotes}
            showTime={showTime}
            setShowTime={setShowTime}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
          />
        )}
      </div>
    </div>
  );
}

export default ControlContainer;
