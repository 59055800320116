// Display.js
import React from 'react';
import QuoteDisplay from './QuoteDisplay';
import TimeDisplay from './TimeDisplay';

function Display({ selectedOption, showQuotes, showTime }) {
  return (
    <div className='display'>
      {selectedOption === 'None' && <div></div>}
      {selectedOption === 'Quotes' && <QuoteDisplay />}
      {selectedOption === 'Time' && <TimeDisplay />}
    </div>
  );
}

export default Display;
