import React, { useEffect, useRef } from 'react';

function BackgroundVideo({ videoSrc, onVideoLoad }) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load(); // Manually reload the video when the source changes
        }
    }, [videoSrc]);

    return (
        <video 
            className="background-video" 
            ref={videoRef} 
            autoPlay 
            loop 
            muted 
            onLoadedData={onVideoLoad}
        >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
}

export default BackgroundVideo;
