// MoreControls.js
import React from 'react';

function MoreControls({ setSelectedOption, selectedOption }) {
  return (
    <div className="controls more">
      <div className="controls-header">
        <h4>More options</h4>
      </div>
      <div>
        <div className="options">
          <button
            className={selectedOption === 'None' ? 'active' : ''}
            onClick={() => setSelectedOption('None')}
          >
            None
          </button>
          <button
            className={selectedOption === 'Quotes' ? 'active' : ''}
            onClick={() => setSelectedOption('Quotes')}
          >
            Quotes
          </button>
          <button
            className={selectedOption === 'Time' ? 'active' : ''}
            onClick={() => setSelectedOption('Time')}
          >
            Time
          </button>
        </div>
      </div>
    </div>
  );
}

export default MoreControls;
