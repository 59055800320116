// Canvas.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import BackgroundVideo from './BackgroundVideo';
import Display from './Display';


function Canvas({
  ambient,
  ambientVolume,
  musicVolume,
  currentPlaylist,
  currentSongIndex,
  isPlaying,
  setCurrentSongIndex,
  showQuotes,
  showTime,
  onNextSong,
  selectedOption, // Added prop to handle the next song
}) {
  const musicAudioRef = useRef(null);
  const [videoLoading, setVideoLoading] = useState(true);
  const [initialPlay, setInitialPlay] = useState(true);

  // Define a function to shuffle the playlist
  const shufflePlaylist = useCallback(() => {
    if (!currentPlaylist || !currentPlaylist.songs) return;
    const shuffledPlaylist = [...currentPlaylist.songs];
    for (let i = shuffledPlaylist.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledPlaylist[i], shuffledPlaylist[j]] = [shuffledPlaylist[j], shuffledPlaylist[i]];
    }
    return shuffledPlaylist;
  }, [currentPlaylist]);

  useEffect(() => {
    const ambientAudioElement = document.getElementById('ambient-audio');
    const currentAudioRef = musicAudioRef.current;
  
    // Set ambient audio volume
    ambientAudioElement.volume = ambientVolume;
  
    // Set music audio volume
    if (currentAudioRef) {
      currentAudioRef.volume = musicVolume;
    }
  
    // Set initial play state
    if (isPlaying) {
      if (initialPlay) {
        setTimeout(() => {
          musicAudioRef.current.play()
            .then(() => {
              console.log("Audio started playing after delay.");
              setInitialPlay(false);
            })
            .catch(error => {
              console.error("Error playing audio after delay:", error);
            });
        }, 1000);
      } else {
        // Check if the music is paused before attempting to play
        if (musicAudioRef.current.paused) {
          // Check if the audio is ready to be played
          if (currentAudioRef.readyState >= 2) {
            musicAudioRef.current.play().catch(error => {
              console.error("Error playing audio:", error);
            });
          } else {
            // If not ready, wait for the canplay event
            const handleCanPlay = () => {
              musicAudioRef.current.play().catch(error => {
                console.error("Error playing audio after canplay event:", error);
              });
              // Remove the event listener after the first canplay event
              currentAudioRef.removeEventListener('canplay', handleCanPlay);
            };
            currentAudioRef.addEventListener('canplay', handleCanPlay);
          }
        }
      }
    } else {
      musicAudioRef.current.pause();
    }
  
    // Event listener for handling audio loading
    const handleAudioLoad = () => {
      // Wait for a short delay to allow the video fade-in animation to complete
      setTimeout(() => {
        // After the delay, hide the loader and set the video to the loaded state
        setVideoLoading(false);
      }, 500); // Adjust the delay time as needed (e.g., 500 milliseconds)
    };
  
    // Event listener for handling the end of audio playback
    const handleAudioEnd = () => {
      // Pause the music when it finishes playing
      musicAudioRef.current.pause();
  
      // Handle the logic for changing to the next song, etc.
      const shuffledPlaylist = shufflePlaylist();
      const nextSongIndex = (currentSongIndex + 1) % shuffledPlaylist.length;
      setCurrentSongIndex(nextSongIndex);
    };
  
    // Attach the event listeners
    if (currentAudioRef) {
      currentAudioRef.addEventListener('canplay', handleAudioLoad);
      currentAudioRef.addEventListener('ended', handleAudioEnd);
    }
  
    // Cleanup: Remove the event listeners
    return () => {
      if (currentAudioRef) {
        currentAudioRef.removeEventListener('canplay', handleAudioLoad);
        currentAudioRef.removeEventListener('ended', handleAudioEnd);
        currentAudioRef.removeEventListener('error', (e) => {
          console.error("Audio error:", e);
        });
      }
    };
  }, [isPlaying, initialPlay, ambientVolume, musicVolume, ambient, setCurrentSongIndex, currentSongIndex, shufflePlaylist]);
      
  
  

  useEffect(() => {
    if (musicAudioRef.current) {
      musicAudioRef.current.volume = musicVolume;
    }
  }, [musicVolume]);

  useEffect(() => {
    if (musicAudioRef.current && currentPlaylist && currentPlaylist.songs && currentSongIndex >= 0 && currentSongIndex < currentPlaylist.songs.length) {
      musicAudioRef.current.src = currentPlaylist.songs[currentSongIndex].url;
      musicAudioRef.current.play().catch(error => {
        console.error("Error playing audio after setting source:", error);
      });
    }
  }, [currentPlaylist, currentSongIndex]);

  useEffect(() => {
    const currentAudioRef = musicAudioRef.current;
    if (currentAudioRef) {
      currentAudioRef.addEventListener('error', (e) => {
        console.error("Audio error:", e);
      });

      currentAudioRef.addEventListener('ended', () => {
        const shuffledPlaylist = shufflePlaylist();
        const nextSongIndex = (currentSongIndex + 1) % shuffledPlaylist.length;
        setCurrentSongIndex(nextSongIndex);
      });

      return () => {
        currentAudioRef.removeEventListener('error', (e) => {
          console.error("Audio error:", e);
        });
      };
    }
  }, [shufflePlaylist, currentSongIndex, setCurrentSongIndex]);

  useEffect(() => {
    setVideoLoading(true); // Set video to loading state when the ambient video changes
  }, [ambient]);

  const handleVideoLoaded = () => {
    setVideoLoading(false); // Hide loader when video is loaded
  };

  return (
    <div>
      {videoLoading && <div className="loader">Loading...</div>}
      <BackgroundVideo
        videoSrc={ambient.video}
        onVideoLoad={handleVideoLoaded}
      />
      <audio id="ambient-audio" src={ambient.audio} loop autoPlay volume={ambientVolume} />
      <audio ref={musicAudioRef} />
      <Display selectedOption={selectedOption} showQuotes={showQuotes} showTime={showTime} />
    </div>
  );
}

export default Canvas;
