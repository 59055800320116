const ambients = [
    {
      id: 1,
      name: 'Mountain',
      video: '/videos/mountain.mp4',
      audio: '/audio/mountain.mp3',
      thumbnail: '/images/mountain.jpg'
    },
    {
      id: 2,
      name: 'Beach',
      video: '/videos/beach.mp4',
      audio: '/audio/beach.mp3',
      thumbnail: '/images/beach.jpg'
    },
    {
      id: 3,
      name: 'Rain',
      video: '/videos/rain.mp4',
      audio: '/audio/rain.mp3',
      thumbnail: '/images/rain.jpg'
    },
    {
      id: 4,
      name: 'Lake',
      video: '/videos/lake.mp4',
      audio: '/audio/rain.mp3',
      thumbnail: '/images/lake.jpg'
    },
    {
      id: 5,
      name: 'Mountains',
      video: '/videos/mountains.mp4',
      audio: '/audio/mountains.mp3',
      thumbnail: '/images/mountains.jpg'
    },
    {
      id: 6,
      name: 'Green Rain',
      video: '/videos/green_rain.mp4',
      audio: '/audio/green_rain.mp3',
      thumbnail: '/images/green_rain.jpg'
    },
    {
      id: 7,
      name: 'Fall',
      video: '/videos/fall.mp4',
      audio: '/audio/fall.mp3',
      thumbnail: '/images/fall.jpg'
    },
    {
      id: 8,
      name: 'Rain in the window',
      video: '/videos/rain_window.mp4',
      audio: '/audio/rain_window.mp3',
      thumbnail: '/images/rain_window.jpg'
    },
  ];
  
  export default ambients;
  