//QuoteDisplay.js

import React, { useState, useEffect } from 'react';
import quotes from '../data/quotesData';

function QuoteDisplay() {
    const [currentQuote, setCurrentQuote] = useState({});
    const [fade, setFade] = useState(false); // state to manage fade-in and fade-out
  
    // Function to fetch a random quote
    const getRandomQuote = () => {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      return quotes[randomIndex];
    };
  
    useEffect(() => {
      const fetchNewQuote = () => {
        const newQuote = getRandomQuote();
        setCurrentQuote(newQuote);
        setFade(true); // start the fade-in
  
        // Set a timeout to fade-out after 9 seconds
        const fadeOutTimeout = setTimeout(() => {
          setFade(false);
        }, 9000);
  
        // Fetch a new quote after total of 9 + 6 = 15 seconds
        const newQuoteTimeout = setTimeout(fetchNewQuote, 15000);
  
        // Cleanup
        return () => {
          clearTimeout(fadeOutTimeout);
          clearTimeout(newQuoteTimeout);
        };
      };
  
      fetchNewQuote();
    }, []);
  
    return (
      <div className={`quote-display ${fade ? 'fade-in' : 'fade-out'}`}>
        <h3>&ldquo;{currentQuote.text}&rdquo;</h3>
        <h5>&#8212; {currentQuote.author}</h5>
      </div>
    );
  }
  
  export default QuoteDisplay;