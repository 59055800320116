// MainApp.js

import React, { useState, useEffect } from 'react';
import Canvas from './Canvas';
import Nav from './Nav/Nav';
import ControlContainer from './ControlContainer';
import ambients from '../data/ambientData';
import playlists from '../data/playlistData';

function MainApp() {
  const [activeControl, setActiveControl] = useState('');
  const [currentAmbient, setCurrentAmbient] = useState(ambients[0]);
  const [ambientVolume, setAmbientVolume] = useState(0.5);
  const [musicVolume, setMusicVolume] = useState(0.5);
  const [currentPlaylist, setCurrentPlaylist] = useState(playlists[0]);
  const [currentSongIndex, setCurrentSongIndex] = useState(
    Math.floor(Math.random() * playlists[0].songs.length)
  );
  const [isPlaying, setIsPlaying] = useState(true);

  const [showControlContainer, setShowControlContainer] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [showQuotes, setShowQuotes] = useState(false);
  const [showTime, setShowTime] = useState(true);

  const [opacity, setOpacity] = useState(0); // State for fade-in effect

  const [selectedOption, setSelectedOption] = useState('None'); // Initialize with 'None'

  useEffect(() => {
    let hideMenuTimer = setTimeout(() => {
      setShowMenu(false);
      setShowControlContainer(false); // Hide ControlContainer along with Nav
    }, 8000);

    const handleMouseMoveClick = () => {
      setShowMenu(true);
      setShowControlContainer(true); // Show ControlContainer along with Nav
      clearTimeout(hideMenuTimer);
      hideMenuTimer = setTimeout(() => {
        setShowMenu(false);
        setShowControlContainer(false); // Hide ControlContainer along with Nav
      }, 8000);
    };

    document.addEventListener('mousemove', handleMouseMoveClick);
    document.addEventListener('click', handleMouseMoveClick);

    return () => {
      clearTimeout(hideMenuTimer);
      document.removeEventListener('mousemove', handleMouseMoveClick);
      document.removeEventListener('click', handleMouseMoveClick);
    };
  }, []);

  useEffect(() => {
    // Fade in the MainApp when it's loaded
    setOpacity(1);
  }, []);

  const fadeOutControlContainer = () => {
    // Add a class to apply the transition (fade-out class)
    const controlContainer = document.querySelector('.control-container');
    controlContainer.classList.remove('fade-in');
    controlContainer.classList.add('fade-out');
  
    // Wait for a short delay to allow the animation to complete
    setTimeout(() => {
      // After the delay, hide the control container and remove the fade-out class
      controlContainer.classList.remove('fade-out');
      setShowControlContainer(false);
      setActiveControl('');
    }, 400); // Adjust the delay time as needed (e.g., 500 milliseconds)
  };
  
  const handleMenuItemClick = (controlName) => {
    if (controlName === activeControl) {
      fadeOutControlContainer();
    } else {
      setShowControlContainer(true);
      setActiveControl(controlName);
    }
  };
  
  const handleDocumentClick = (e) => {
    if (!e.target.closest('.wrapper') && !e.target.closest('.menu-items')) {
      fadeOutControlContainer();
    }
  };
  
  

  

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const changeAmbient = (newAmbient) => {
    setCurrentAmbient(newAmbient);
  };

  const handleMusicVolumeChange = (volume) => {
    setMusicVolume(volume);
  };

  const handlePlaylistChange = (playlist) => {
    setCurrentPlaylist(playlist);
  };

  useEffect(() => {
    console.log("MainApp ambientVolume:", ambientVolume);
  }, [ambientVolume]);

  return (
    <div className="main-app" style={{ opacity: opacity }}>
      <div className="top-nav">
        <h5 className="logo">Calmood</h5>
      </div>
      <Nav 
        showMenu={showMenu} 
        onMenuItemClick={handleMenuItemClick} 
        activeControl={activeControl} 
        showControlContainer={showControlContainer} 
        selectedOption={selectedOption} // Pass selectedOption to Nav
        setSelectedOption={setSelectedOption} // Pass setSelectedOption to Nav
      />

      {showMenu && showControlContainer && (
        <ControlContainer
          activeControl={activeControl}
          onAmbientChange={changeAmbient}
          currentAmbient={currentAmbient}
          playlists={playlists}
          currentPlaylist={currentPlaylist}
          currentSongIndex={currentSongIndex}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          onPrevSong={() => setCurrentSongIndex((prevIndex) => prevIndex === 0 ? currentPlaylist.songs.length - 1 : prevIndex - 1)}
          onNextSong={() => {
            // Calculate the next song index
            let nextIndex = currentSongIndex + 1;

            // Check if the nextIndex is greater than or equal to the number of songs
            if (nextIndex >= currentPlaylist.songs.length) {
              // If so, loop back to the first song
              nextIndex = 0;
            }

            // Set the new song index
            setCurrentSongIndex(nextIndex);
          }}
          ambientVolume={ambientVolume}
          onAmbientVolumeChange={setAmbientVolume}
          musicVolume={musicVolume}
          onMusicVolumeChange={handleMusicVolumeChange}
          onPlaylistChange={handlePlaylistChange}
          showQuotes={showQuotes}
          setShowQuotes={setShowQuotes}
          showTime={showTime}
          setShowTime={setShowTime}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )}
      <Canvas
        ambient={currentAmbient}
        ambientVolume={ambientVolume}
        musicVolume={musicVolume}
        currentPlaylist={currentPlaylist}
        currentSongIndex={currentSongIndex}
        isPlaying={isPlaying}
        showQuotes={showQuotes}
        showTime={showTime}
        setCurrentSongIndex={setCurrentSongIndex}
        selectedOption={selectedOption} // Pass selectedOption to Canvas
      />
    </div>
  );
}

export default MainApp;
