import React from "react";

function MusicControls({
  playlists,
  currentPlaylist,
  currentSongIndex,
  isPlaying,
  setIsPlaying,
  onPrevSong,
  onNextSong,
  ambientVolume,
  onAmbientVolumeChange,
  musicVolume,
  onMusicVolumeChange,
  onPlaylistChange,
  shufflePlaylist, // Add shufflePlaylist prop
}) {
  function handlePlayPause() {
    setIsPlaying((prevState) => !prevState);
  }

  function handleNextSong() {
    // Calculate the next song index
    let nextIndex = currentSongIndex + 1;

    // Check if the nextIndex is greater than or equal to the number of songs
    if (nextIndex >= currentPlaylist.songs.length) {
      // If so, loop back to the first song
      nextIndex = 0;
    }

    // Call the onNextSong function with the new index
    onNextSong(nextIndex);
  }

  function handlePrevSong() {
    onPrevSong();
  }

  function handlePlaylistSelection(playlistId) {
    const selectedPlaylist = playlists.find(
      (playlist) => playlist.id === playlistId
    );
    onPlaylistChange(selectedPlaylist);
  }

  return (
    <div className="controls music">
      <div className="controls-header">
        <h4>Music</h4>
        <div className="play-controls">
          <button onClick={handlePrevSong}>
            {/* Previous button */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
            >
              <path d="M250-240q-13 0-21.5-8.5T220-270v-420q0-13 8.5-21.5T250-720q13 0 21.5 8.5T280-690v420q0 13-8.5 21.5T250-240Zm443-33L430-455q-13-9-13-25t13-25l263-182q15-11 31-2.5t16 26.5v366q0 18-16 26.5t-31-2.5Zm-13-207Zm0 125v-250L499-480l181 125Z" />
            </svg>
          </button>
          <button onClick={handlePlayPause}>
            {isPlaying ? (
              /* Pause button */
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path d="M400.175-320q12.825 0 21.325-8.625T430-350v-260q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T370-610v260q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm160 0q12.825 0 21.325-8.625T590-350v-260q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T530-610v260q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM480.266-80q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" />
              </svg>
            ) : (
              /* Play button */
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path d="m418-332 202-129q11-7 11-19t-11-19L418-628q-11-8-23-1.5T383-609v258q0 14 12 20.5t23-1.5Zm62 252q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
              </svg>
            )}
          </button>
          <button onClick={handleNextSong}>
            {/* Next button */}
            
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
            >
              <path d="M709.825-240Q697-240 688.5-248.625T680-270v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T740-690v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM267-273q-15 11-31 2.25T220-297v-366q0-17.5 16-26.25t31 2.25l263 182q13 9.111 13 25.056Q543-464 530-455L267-273Zm13-207Zm0 125 181-125-181-125v250Z" />
            </svg>
          </button>
        </div>
        <div className="volume-controls">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 0 24 24"
              width="20"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"
                fill="currentColor"
              />
            </svg>
          <input
            type="range"
            id="music-volume"
            min="0"
            max="1"
            step="0.1"
            value={musicVolume}
            onChange={(e) => onMusicVolumeChange(parseFloat(e.target.value))}
          />
        </div>
      </div>
      <div className="controls-content">
        
        
        
      </div>

      <div className="options">
        {playlists.map((playlist) => (
          <button
            key={playlist.id}
            onClick={() => handlePlaylistSelection(playlist.id)}
            className={
              currentPlaylist && playlist.id === currentPlaylist.id
                ? "active"
                : ""
            }
          >
            {playlist.name}
          </button>
        ))}
      </div>
    </div>
  );
}

export default MusicControls;
