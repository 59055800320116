const playlists = [
    {
      id: 1,
      name: 'Focus',
      songs: [
        { name: 'Song 1',url: '/music/focus/595_253549_253549_173cb00302dc42d6aaa5d35043543e17.mp3' },
        { name: 'Song 2', url: '/music/focus/13936_260161_260161_Purify_-_Master_-16-44.1-.mp3' },
        { name: 'Song 3', url: '/music/focus/60893_491923_491923__Marshall_Thomas_Usinger_-_Emotion_In_Motion_-_Fluttering_-_AO-000193_-_1_-_Master_-_070221_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 4', url: '/music/focus/88394_674680_674680__Christopher_Galovan_-_Holding_on_to_Hope_-_Master_-_104_Bpm_-_240422_-_EXT_-_2444.mp3' },
        { name: 'Song 5', url: '/music/focus/91316_689539_689539__Risian_-_Carry_On_-_Master_-_101_BPM_-_310522_-_EXT_-_2444.mp3' },
        { name: 'Song 6', url: '/music/focus/93952_707873_707873__Circle_of_Life_-_Lennart_Floris_Schroot_-_AO-000537_-_Master_-_050122_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 7', url: '/music/focus/94086_709389_709389__Michael_Vignola_-_Swept_In_-_Master_-_No_BPM_-_200722_-_EXT_-_RMS_-_2444.mp3' },
        { name: 'Song 8', url: '/music/focus/746391_742312_Michael_Timothy_Graff_-_Cinematic_Ambiance_-_AO-000865-1_-_Master_-_040922_-_84_Bpm_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 9', url: '/music/focus/781683_Salt_of_the_Sound_-_Midwinter_-_Master_-_50_Bpm_-_071222_-_EXT_-_2444.mp3' },
        { name: 'Song 10', url: '/music/focus/798014_791166_Ofri_Flint_-_Hopefully_Ambient_-_IH-000062-2_-_Master_V1_-_90_Bpm_-_211122_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 11', url: '/music/focus/798589_795451_Michael_Timothy_Graff_-_Evolving_Atmospheres_-_AO-000951-1_-_Master_V2_-_No_Bpm_-_301022_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 12', url: '/music/focus/803385_797248_Roie_Shpigler_Raz_-_Pianissimo_-_AO-001011-2_-_Master_-_123_Bpm_-_091122_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 13', url: '/music/focus/807255_Josh_McCausland_-_TBD_-_EX-000051-1_-_Master_-_No_Bpm_-_170123_-_BOV_-_EXT_-_2444.mp3' },
        { name: 'Song 14', url: '/music/focus/831933_831581_Adiel_Alexander_Goldstein_-_Modular_Synth_3_-_AO-000896-7_-_Master_-_No_Bpm_-_180922_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 15', url: '/music/focus/831935_831574_Adiel_Alexander_Goldstein_-_Modular_Synth_3_-_AO-000896-4_-_Master_-_No_Bpm_-_150922_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 16', url: '/music/focus/831940_831587_Adiel_Alexander_Goldstein_-_Modular_Synth_4_-_AO-000897-3_-_Master_-_No_Bpm_-_110922_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 17', url: '/music/focus/842577_839835_Circle_of_Life_-_Lennart_Floris_Schroot_-_AO-000537_-_Master_-_76_Bpm_-_090523_-_AV_-_Slowed_Down_-_ORG_-_2444.mp3' },
        { name: 'Song 18', url: '/music/focus/843106_Nobou_-_Lifelines_-_EX-000169_-_Master_-_82_Bpm_-_130423_-_BOV_-_EXT_-_2444.mp3' },
        { name: 'Song 19', url: '/music/focus/844309_843327_Sean_Robert_Williams_-_Ambi-Coustic_-_AO-001181-3_-_Master_V2_-_70_Bpm_-_300323_-_IV_-_ORG-2444.mp3' },
        
      ],
    },
    {
      id: 2,
      name: 'Zen',
      songs: [
        { name: 'Song 1', url: '/music/zen/44263_418496_418496__Emmanuel_Jacob_-_Blossoming_Thought_-_211020_-_EXT_-_X_-_2444.mp3' },
        { name: 'Song 2', url: '/music/zen/44265_418513_418513__Emmanuel_Jacob_-_Fields_of_Meditation_-_211020_-_EXT_-_X_-_2444.mp3' },
        { name: 'Song 3', url: '/music/zen/60544_489713_489713__Yotam_Agam_-_Flutelsland_-_Dance_with_the_Gods_-_AO-000238_-_2_-_Master_V2_-_BOV_-_230221_-_ORG_-_2444.mp3' },
        { name: 'Song 4', url: '/music/zen/80369_612294_612294_Yotam_Agam_-_Solfeggio_-_AO-000497-4_-_Master_V1_-_141021_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 5', url: '/music/zen/80887_645338_645338_Yotam_Agam_-_Binaural_-_AO-000499-1_-_Master_V1a_-_131021_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 6', url: '/music/zen/92956_695704_695704__Beneath_The_Mountain_-_Holding_Hands_-_Master_-_45_Bpm_-_190622_-_EXT_-_2444.mp3' },
        { name: 'Song 7', url: '/music/zen/95474_706745_706745__Sean_Robert_Williams_-_Cinematic_Beds_-_AO-000747-1_-_Master_-_60_Bpm_-_240522_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 8', url: '/music/zen/97172_710924_710924__Adiel_Alexander_Goldstein_-_Modular_Ambient_-_AO-000736-1_-_Master_-_No_Bpm_-_100522_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 9', url: '/music/zen/723691_The-SoundKeeper---The-View-From-The-Attic-Window---Master---61-BPM---160822---EXT---2444.mp3' },
        { name: 'Song 10', url: '/music/zen/725020_Adiel_Alexander_Goldstein_-_Modular_Ambient_-_AO-000736-2_-_Master_-_No_Bpm_-_100522_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 11', url: '/music/zen/733642_710085_Kirk_Kienzle_Smith_-_Ambiatic_-_AO-000785-1_-_Master_-_220622_-_58_Bpm_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 12', url: '/music/zen/733914_Be_Still_the_Earth_-_Laengsel_-_Master_-_46.5_Bpm_-_080922_-_EXT_-_2444.mp3' },
        { name: 'Song 13', url: '/music/zen/737531_708431_Adiel_Alexander_Goldstein_-_Modular_Ambient_-_AO-000736-18_-_Master_-_No_Bpm_-_160522_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 14', url: '/music/zen/737533_708436_Adiel_Alexander_Goldstein_-_Modular_Ambient_-_AO-000736-13_-_Master_-_No_Bpm_-_160522_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 15', url: '/music/zen/737538_708437_Adiel_Alexander_Goldstein_-_Modular_Ambient_-_AO-000736-11_-_Master_-_No_Bpm_-_160522_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 16', url: '/music/zen/747238_743412_Marshall_Thomas_Usinger_-_Tree_Memories_-_AO-000793-3_-_Master_-_130722_-_57_Bpm_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 17', url: '/music/zen/747241_743411_Marshall_Thomas_Usinger_-_Tree_Memories_-_AO-000793-3_-_Master_-_070922_-_57_Bpm_-_IV_-_ORG_-_2444.mp3' },
        { name: 'Song 18', url: '/music/zen/761556_Liquid_Memoirs_-_Waking_Up_in_Limbo_-_Master_-_No_Bpm_-_021122_-_EXT_-_2444.mp3' },
        { name: 'Song 19', url: '/music/zen/761706_Liquid_Memoirs_-_Bittersweet_Nightmare_-_Master_-_No_Bpm_-_021122_-_EXT_-_2444___.mp3' },
      ],
    },

    {
      id: 3,
      name: 'Study',
      songs: [
          { name: 'Song 1', url: '/music/study/93370_684269_684269__Itamar_Gross_-__Piano_Solo_-_Vol_I_-_AO-000626-2_-_Master_V2_-_No_Bpm_-_120422_-_BOV_-_ORG_-_2444.mp3' },
          { name: 'Song 2', url: '/music/study/95006_711304_711304__Ardie_Son_-_Suite_no_I_-_Master_-_No_Bpm_-_270722_-_EXT_-_2444.mp3' },
          { name: 'Song 3', url: '/music/study/95475_706751_706751__Sean_Robert_Williams_-_Cinematic_Beds_-_AO-000747-2_-_Master_-_70_Bpm_-_310522_-_BOV_-_ORG_-_2444.mp3' },
          { name: 'Song 4', url: '/music/study/715361_Ido-kashi---Feutre-Et-Papoer---IH-000002-3---Master-V2---200622---150-Bpm---BOV---ORG---2444.mp3' },
          { name: 'Song 5', url: '/music/study/716305_Birraj---In-a-Dream---Master---90-Bpm---070822---EXT---2444.mp3' },
          { name: 'Song 6', url: '/music/study/741960_Anna_Elisa_Pancaldi_-_Road_Trip_Mix_Tape_-_AO-000720-2_-_Master_V2_-_91_Bpm_-_050922_-_IV_-_ORG_-_2444_-_RX.mp3' },
          { name: 'Song 7', url: '/music/study/765166_Dear_Gravity_-_Grace_-_Master_-_100_Bpm_-_021122_-_EXT_-_2444.mp3' },
          { name: 'Song 8', url: '/music/study/767701_Boreis_-_Beaver_Moon_-_Master_-_75_Bpm_-_101122_-_EXT_-_2444.mp3' },
          { name: 'Song 9', url: '/music/study/774732_747832_Sean_Robert_Williams_-_Aero_-_AO-000876-2_-_Master_-_85_Bpm_-_080922_-_BOV_-_ORG_-_2444.mp3' },
          { name: 'Song 10', url: '/music/study/803383_797247_Roie_Shpigler_Raz_-_Pianissimo_-_AO-001011-1_-_Master_-_No_Bpm_-_311022_-_BOV_-_ORG_-_2444.mp3' },
          { name: 'Song 11', url: '/music/study/808200_802291_Ofri_Flint_-_Modern_Technology_-_IH-000052-1_-_Master_-_231122_-_110_Bpm_-_BOV_-_ORG_-_2444.mp3' },
          { name: 'Song 12', url: '/music/study/808548_807436_Ido_Kashi_-_Unraveling_Whats_Under_-_IH-000063-1_-_Master_-_No_Bpm_-_291122_-_BOV_-_ORG_-_2444.mp3' },
          { name: 'Song 13', url: '/music/study/808902_808288_Ofri_Flint_-_Present_In_The_Past_-_IH-000071_-_Master_V1a__-_105_Bpm_-_030123_-_BOV_-_ORG_-_2444.mp3' },
          { name: 'Song 14', url: '/music/study/815758_Simon_Wester_-_Goodness_-_EX-000111-1_-_Master_-_70_Bpm_-_130223_-_BOV_-_EXT_-_2444.mp3' },
          { name: 'Song 15', url: '/music/study/815759_Simon_Wester_-_Goodness_-_EX-000111-2_-_Master_-_50.5_Bpm_-_130223_-_BOV_-_EXT_-_2444.mp3' },
          { name: 'Song 16', url: '/music/study/830496_Yoav_Ilan_-_Lullabies_for_Piano_-_EX-000137-2_-_Master_-_220323_-_BOV_-_EXT_-_2444.mp3' },
          { name: 'Song 17', url: '/music/study/845294_844538_Roie_Shpigler_Raz_-_Pianissimo_2_-_AO-001274-3_-_Master_-_52_Bpm_-_120323_-_BOV_-_ORG_-_2444.mp3' },
      ]
    },
  
    {
      id: 4,
      name: "Lofi",
      songs: [
        { name: 'Song 1', url: '/music/lofi/51020_444938_444938__Phury_-_Early_Morning_-_161220_-_EXT_-_X_-_2444_.mp3' },
        { name: 'Song 2', url: '/music/lofi/72091_583330_583330__Ottom_-_A_Drift_-_Master_-_201021_-_EXT_-__2444.mp3' },
        { name: 'Song 3', url: '/music/lofi/80923_612030_612030_Dotan_Natanel_Bergman_-_A_Zebra_Catching_up_on_Its_Emails_-_AO-000525-2_-_Master_-_231121_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 4', url: '/music/lofi/83229_639604_639604__Yestalgia_-_Fly_with_Me_-_Master_V2_-_78_Bpm_-_020222_-_EXT_-_2444.mp3' },
        { name: 'Song 5', url: '/music/lofi/93143_696986_696986__92elm_-_Suncatcher_-_Master_-_84_Bpm_-_280622_-_EXT_-_2444___.mp3' },
        { name: 'Song 6', url: '/music/lofi/94393_698962_698962__Fabien_Fustinoni_-_The_Sun_Is_In_Your_Eyes_-_Master_-_77_BPM_-_030722_-_EXT_-_2444.mp3' },
        { name: 'Song 7', url: '/music/lofi/94415_699795_699795__Skygaze_-_The_Light_Tonight_-_Master_-_84_Bpm_-_EXT_-_2444______.mp3' },
        { name: 'Song 8', url: '/music/lofi/94763_710058_710058__Manor_Gabay_-_Fi-Lo_-_AO-000655-1_-_Master_V1_-_No_Bpm_-_150222_-_SV_No_Lead_Vocals_-_ORG_-_2444.mp3' },
        { name: 'Song 9', url: '/music/lofi/94773_699466_699466__Steven_Richard_Beddall_-_Stevens_Buyout_I_-_AO-000824-1_-_Master_V1_-_80_Bpm_-_310522_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 10', url: '/music/lofi/94775_699461_699461__Steven_Richard_Beddall_-_Stevens_Buyout_I_-_AO-000824-2_-_Master_V1_-_90_Bpm_-_310522_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 11', url: '/music/lofi/714055_Notize---Past-Light---Master---83-BPM---310722---EXT---2444.mp3' },
        { name: 'Song 12', url: '/music/lofi/720582_MILANO---Study-Buddy---Master---78-BPM---140822---EXT---2444---.mp3' },
        { name: 'Song 13', url: '/music/lofi/737287_FAIR_GAME_-_Crashing_-_Master_-_134_Bpm_-_130922_-_EXT_-_2444.mp3' },
        { name: 'Song 14', url: '/music/lofi/753805_Kitrano_-_Mystic_-_Master_-_80_Bpm_-_161022_-_EXT_-_2444.mp3' },
        { name: 'Song 15', url: '/music/lofi/763951_747163_Roy_Yarom_-_Samurai_Royal_-_AO-000801-1_-_Master_V1_-_145_Bpm_-_010922_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 16', url: '/music/lofi/771117_Yestalgia_-_Into_the_Galaxy_-Feat_Bad_Room_Producer-_-_Master_-_70_Bpm_-_171122_-_EXT_-_2444.mp3' },
        { name: 'Song 17', url: '/music/lofi/785640_769798_Ofri_Flint_-_A_Chill_Sunday_-_IH-000032-1_-_Master_-_131022_-_90_Bpm_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 18', url: '/music/lofi/826962_826678_826393_Artem_Vyacheslavovich_Hramushkin_-_Tokyo_-_Shinagawa_-_CE-000014-5_-_Master_-_86_Bpm_-_211222_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 19', url: '/music/lofi/826966_826423_Artem_Vyacheslavovich_Hramushkin_-_Tokyo_-_Shinjuku_-_CE-000014-6_-_Master_-_77_Bpm_-_211222_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 20', url: '/music/lofi/828282_826471_Manor_Gabay_-_Skill_Loop_-_IH-000018-3_-_Master_-_80bpm_-_031022_-_BOV_-_ORG_-_2444.mp3' },
        { name: 'Song 21', url: '/music/lofi/842248_837513_Ayush_Kumar_Yadav_-_Wav_Two_-_TBD_-_BO-000021-4_-_Master_V1_-_50_Bpm_-_010223_-_BOV_-_ORG_-_2444.mp3' }
      ]
    },
  
    // Add more playlists as needed
  ];
  
  export default playlists;
  