//Nav.js

import React, { useState, useEffect } from "react";
import EmailModal from "../EmailModal";



function Nav({ onMenuItemClick, activeControl, showMenu }) {
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    // Add a listener for fullscreen change
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    // Cleanup the listener when the component unmounts
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleFullscreenChange = () => {
    // Update the state based on the current fullscreen state
    setIsFullscreen(!!document.fullscreenElement);
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // If not in fullscreen, enter fullscreen mode
      document.documentElement.requestFullscreen();
    } else {
      // If in fullscreen, exit fullscreen mode
      document.exitFullscreen();
    }
  };

  const handleItemClick = (controlName) => {
    onMenuItemClick(controlName);
  };

  const navStyle = {
    opacity: showMenu ? 1 : 0,
    transition: 'opacity 1s ease-in-out',
    pointerEvents: showMenu ? 'auto' : 'none'
  };

  return (
    <div className="nav" style={navStyle}>
      <div className="top-right-nav">
      <button className="fullscreen" onClick={toggleFullscreen}>
        {isFullscreen ? (
          
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path></svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
          </svg>
        )}
        <div className="buttonLabel">Fullscreen</div>
      </button>
      
{
  //    <button className="subscribe-btn" >
  //      <svg
  //        xmlns="http://www.w3.org/2000/svg"
  //        width="24"
  //        height="24"
  //        viewBox="0 0 24 24"
  //        fill="none"
  //        stroke="currentColor"
  //        strokeWidth="2"
  //        strokeLinecap="round"
  //        strokeLinejoin="round"
  //      >
  //        <circle cx="12" cy="12" r="10" />
  //        <line x1="12" y1="16" x2="12" y2="12" />
  //        <line x1="12" y1="8" x2="12" y2="8" />
  //      </svg>
  //      <div className="buttonLabel">Subscribe</div>
  //    </button>
}
      </div>
          
      
      <div className="menu-items">
        <button
          className={`menu-btn ambient-btn ${activeControl === "Ambient" ? "active" : ""}`}
          onClick={() => handleItemClick("Ambient")}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <circle cx="8.5" cy="8.5" r="1.5"></circle>
            <polyline points="21 15 16 10 5 21"></polyline>
          </svg>
          <div className="buttonLabel">Ambient</div>
        </button>
        <button
          className={`menu-btn music-btn ${activeControl === "Music" ? "active" : ""}`}
          onClick={() => handleItemClick("Music")}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M9 18V5l12-2v13"></path>
            <circle cx="6" cy="18" r="3"></circle>
            <circle cx="18" cy="16" r="3"></circle>
          </svg>
          <div className="buttonLabel">Music</div>
        </button>
        <button
          className={`menu-btn more-btn ${activeControl === "More" ? "active" : ""}`}
          onClick={() => handleItemClick("More")}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
          </svg>
          <div className="buttonLabel">More</div>
        </button>
      </div>
     
    </div>
  );
}

export default Nav;
